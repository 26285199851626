// colorReducer.js
import { SET_SELECTED_COLOR, SET_SELECTED_NAME_COLOR } from "./actions";

const initialState = {
  selectedColor: null, // For title color
  selectedNameColor: null, // For name color
};

const colorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_COLOR:
      return {
        ...state,
        selectedColor: action.payload,
      };
    case SET_SELECTED_NAME_COLOR:
      return {
        ...state,
        selectedNameColor: action.payload,
      };
    default:
      return state;
  }
};

export default colorReducer;
