// tabSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTab: "GreenLine Mobile", // Default active tab
};

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload; // Update active tab
    },
  },
});

export const { setActiveTab } = tabSlice.actions; // Export action creator
export const activeTabSelector = (state) => state.tab.activeTab; // Selector for active tab

export default tabSlice.reducer; // Export reducer
