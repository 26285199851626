import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import check from "../assets/images/check.png";
import iconetherium from "../assets/images/small-logo.png";
import label from "../assets/images/label.png";
import { API_URL, emailRegex, isValidRecipient, ScreeNname } from "../constant";
import { toast } from "react-toastify";
import { handleDeleteNFT, sendNftToUserByEmail } from "../shared/api";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { rightSelector } from "../redux/rightSlice";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";

function CollectionPost({ nftData, dataAfterDelete, parentdata, pardata ,getNftColletion}) {
  const [days, setDays] = useState();
  const { rightAccess } = useSelector(rightSelector);
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [isDelete, setisDelete] = useState(false);
  const [isSend, setisSend] = useState(false);
  const [expired, setexpired] = useState(false);
  const [recipient, setRecipient] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const DistributionType = {
    MarketPlace:'MarketPlace'
  }

  const NFT_TYPE =
{
  "MarketPlace": 'MarketPlace',
  "Product": 'Product',
  "Tier": 'Tier',
  "promotion": 'Promotion'
}

  const [isInterval, setIsInterval] = useState(false);
  const deleteNFT = (id) => {
    setisDelete(false)
    handleDeleteNFT(id)
      .then((res) => {
        toast.info("NFT Deleted Successfully");
        dataAfterDelete(id);
      })
      .catch((err) => { });
  };
// Function to handle changes in the email input field
  const handleRecipientChange = (e) => {
    const recipient = e.target.value;
    setRecipient(recipient);
  
    // Only show an error if the input is not empty and it's invalid
    if (recipient && !isValidRecipient(recipient)) {
      setErrorMessage("Please enter a valid email, phone, or wallet address.");
    } else {
      setErrorMessage(""); // Clear error if recipient is valid or input is empty
    }
  };

  // Function to send an NFT to a user by email
  const sendNFT = async (id) => {
    if (!recipient) {
      toast.error("Please enter a email.");
      return;
    }
    if (!isValidRecipient(recipient)) {
      return;
    }
  
    setLoading(true); 

    try {
      const result = await sendNftToUserByEmail(recipient,id)
      setisSend(false)
      toast.success("NFT sent successfully!");
      setErrorMessage("");
      setRecipient(""); // 
      getNftColletion()

    } catch (error) {
      toast.error(error)
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
   
      
  };
  useEffect(() => {

    if (nftData) {
      const currentDate = moment().format('YYYY-MM-DD');
      const expiryDate = moment(nftData?.expiryDate);

      // Set the expiry time to 23:59:59 if the expiry date is today
      if (expiryDate.isSame(currentDate, 'day')) {
        expiryDate.set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        });
      } else if (expiryDate.isAfter(moment().endOf('year'))) {
        // If the expiry date is in the next year, set it to the start of the next year
        expiryDate.set({
          month: 0, // January (zero-based index)
          date: 1,
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      const isExpired = expiryDate.isSameOrBefore(moment());
      setexpired(isExpired);

      const currentTime = moment.utc().unix();
      const endTime = moment(nftData?.distributaionType === 'MarketPlace' ? nftData?.bidEndDate : expiryDate).utc().unix();
      const diffTime = endTime - currentTime;

      let duration = moment.duration(diffTime * 1000, 'milliseconds');
      const interval = 1000;

      var timerID = setInterval(() => {
        setIsInterval(true);
        if (duration._milliseconds <= 0) {
          setDays('0');
          setHours('0');
          setMinutes('0');
          setSeconds('0');
          clearInterval(timerID); // Clear the interval when the countdown reaches zero
        } else {
          duration = moment.duration(duration - interval, 'milliseconds');
          setDays(parseInt(duration.asDays()));
          setHours(duration.hours());
          setMinutes(duration.minutes());
          setSeconds(duration.seconds());
        }
      }, interval);
      return () => clearInterval(timerID);

    }
  }, [nftData]);

  const handleClose = () => setisDelete(false);
  const handleCloseSend = () => {setisSend(false);
    setErrorMessage("");
    setRecipient("");
  }

  const LikeNFT = (id) => {
    let data = {
      "nftId": id,
      "nftAddress": ""
    }
    axios
      .post(API_URL + `api/Nft/AddFavouriteNft`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((x) => {

        if (pardata) {

          parentdata(false)
        } else {
          parentdata(true)

        }
      })
      .catch((x) => {


      });
  };
  const UnLikeNFT = (id) => {
    let data = {
      "nftId": id,
      "nftAddress": ""
    }
    axios
      .put(API_URL + `api/Nft/RemoveFavouriteNft`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((x) => {
        if (pardata) {

          parentdata(false)
        } else {
          parentdata(true)

        }


      })
      .catch((x) => {


      });
  };

  return (
    <>
      <Modal centered show={isDelete} onHide={handleClose}>
        <Modal.Header>

          <Button closeButton className="close-btn" onClick={handleClose}><i className="fa fa-close"></i></Button>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <h3 style={{ display: "flex", justifyContent: "center" }}>
                Are you sure you want to delete this NFT?
              </h3>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Row>
            <Col md={6}>
              <Button
                onClick={() => deleteNFT(nftData?.id)}
                style={{ minWidth: "195px" }}
                className="reg-btn w-100 big"
              >
                Yes
              </Button>
            </Col>
            <Col md={6}>
              <Button
                onClick={handleClose}
                style={{ minWidth: "195px" }}
                className="reg-btn w-100 trans big"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal centered show={isSend} onHide={handleCloseSend}>
      <Modal.Header>
        <Button closeButton className="close-btn" onClick={handleCloseSend}>
          <i className="fa fa-close"></i>
        </Button>
        <Modal.Title>Send NFT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <h3 style={{ display: "flex", justifyContent: "center" }}>
            Enter the recipient's email, phone, or wallet address to send this NFT:
            </h3>
            <input
              type="email"
              placeholder="Recipient's email, phone, or wallet address"
              className="form-control mt-3"
              value={recipient}
              onChange={handleRecipientChange}
    // Validate on change

            />
            {errorMessage && (
              <p className="errormessage red">
                {errorMessage}
              </p>
            )}
           
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Row>
          <Col md={6}>
          

            <Button
             onClick={() => sendNFT(nftData?.id)}
              style={{ minWidth: "195px" }}
                className="reg-btn w-100 big"
              disabled={loading} // Disable button if email is empty or loading
            >
              {loading ? (
                <PulseLoader
                  color="#ffffff" // Adjust color as needed
                  loading={loading}
                  size={10} // Adjust size as needed
                  margin={2}
                />
              ) : (
                "Send"
              )}
            </Button>
          </Col>
          <Col md={6}>
            <Button
              onClick={handleCloseSend}
              style={{ minWidth: "195px" }}
              className="reg-btn w-100 trans big"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>

      <div className="collection-post small">
        <div className="banner-pnl">
          <div
            className="parentdiv"
          // className="bg-layer"
          // style={{
          //   backgroundImage: `url(${API_URL}${nftData?.distributaionType === 'MarketPlace' ?
          //     nftData?.image?.replaceAll(
          //       "\\",
          //       "/"
          //     ) : nftData?.activeImage?.replaceAll(
          //       "\\",
          //       "/"
          //     )})`,
          // }}
          >
            
            {(nftData?.isClaim === false && nftData?.isDistribute === false) && <div className="label-container">

              <img src={label} className="label-image" />
            </div>}
            <img
              src={`${API_URL}${nftData?.isUsed ? nftData?.usedImage?.replaceAll(
                "\\",
                "/"
              ) : nftData?.distributionType === 'Promotion' ||nftData?.distributionType  ===  DistributionType.MarketPlace ?
                nftData?.image?.replaceAll(
                  "\\",
                  "/"
                ) : expired ? nftData?.expiredImage?.replaceAll(
                  "\\",
                  "/"
                ) : nftData?.activeImage?.replaceAll(
                  "\\",
                  "/"
                )}`}
              alt="Banner Image"
              className="imgindiv"
            />
          </div>
          
              
        {
          <>{ 
            ( (nftData?.isClaim === false && nftData?.isDistribute === false  && !nftData?.isNftDistributed  === false )
               || (nftData?.distributionType == NFT_TYPE.MarketPlace && !nftData?.isClaim )
               ||(nftData?.distributionType == NFT_TYPE.Product )
               ||(nftData?.distributionType ==  NFT_TYPE.promotion &&  nftData?.ownerAccountId == null )
            ) &&  
            <>

                 {(nftData?.distributionType == NFT_TYPE.Product || nftData?.distributionType == NFT_TYPE.Tier || nftData?.distributionType == NFT_TYPE.MarketPlace  ) && (
            <Dropdown className="post-menu">
              <Dropdown.Toggle id="dropdown-basic">
                <i className="fa fa-ellipsis-h"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>

                {rightAccess?.some(
                  (right) =>
                    right.screenName.toLowerCase() ==
                    ScreeNname.nftCollection && right.isUpdate
                ) && (
                    <Dropdown.Item href={`/updateNft/${nftData?.id}`}>
                      Edit
                    </Dropdown.Item>
                  )}

                <Dropdown.Item href={`/CollectionDetail/${nftData?.id}`}>
                  View
                </Dropdown.Item>
                {rightAccess?.some(
                  (right) =>
                    right.screenName.toLowerCase() ==
                    ScreeNname.nftCollection && right.isDelete
                ) && (
                    <>
                      <Dropdown.Item href="#" onClick={() => setisDelete(true)}>
                        Delete
                      </Dropdown.Item>
                    </>
                  )}
              </Dropdown.Menu>
            </Dropdown>
          )
          }
          {nftData?.distributionType == NFT_TYPE.promotion && (
            <Dropdown className="post-menu">
              <Dropdown.Toggle id="dropdown-basic">
                <i className="fa fa-ellipsis-h"></i>
              </Dropdown.Toggle>

           
              <Dropdown.Menu>

              {rightAccess?.some(
                  (right) =>
                    right.screenName.toLowerCase() ==
                    ScreeNname.nftCollection && right.isUpdate
                ) && (
                    <Dropdown.Item href={`/updateNft/${nftData?.id}`}>
                      Edit
                    </Dropdown.Item>
                  )}
                <Dropdown.Item href={`/CollectionDetail/${nftData?.id}`}>
                  View
                </Dropdown.Item>
                {rightAccess?.some(
                  (right) =>
                    right.screenName.toLowerCase() ==
                    ScreeNname.nftCollection && right.isDelete
                ) && (
                    <>
                      <Dropdown.Item href="#" onClick={() => setisDelete(true)}>
                        Delete
                      </Dropdown.Item>
                    </>
                  )}
                   
                    <>
                      <Dropdown.Item href="#" onClick={() => setisSend(true)}>
                        Send
                      </Dropdown.Item>
                    </>
                  
              </Dropdown.Menu>
            </Dropdown>
          )
          }
            </>
            
            }  </>
        }
     


        </div>
        <Link to={`/CollectionDetail/${nftData?.id}`} className="txt-pnl">
          <div className="heading heading-min-height" >
            <h4>
              {`${nftData?.name?.substring(0, 16)}\n${nftData?.name?.substring(16)}`}
              <img src={check} alt="checked" />
            </h4>
            <h4>
              #${nftData?.id.substring(0, 4)}...$
              {nftData?.id.substring(nftData?.id?.length - 4)}`
            </h4>
            {
            
              nftData?.isClaim && 
              <div className="time-pnl">
                <>
              {nftData?.distributaionType == 'Promotion' ? nftData?.isBidOpen && (
                <>
                <p>
                  {days ? days : 0}d {hours ? hours : 0}h {minutes ? minutes : 0}m{" "}
                  {seconds ? seconds : 0}s
                </p>
                              <p>Expiry Date : {nftData?.expiryDate?.split("T")[0]}</p>
                              </>

                
                

              ) : <p>
                {days ? days : 0}d {hours ? hours : 0}h {minutes ? minutes : 0}m{" "}
                {seconds ? seconds : 0}s
              </p>}
              <p>Expiry Date : {nftData?.expiryDate.split("T")[0]}</p>
              </>
            </div>
            }
            {nftData?.distributaionType == 'MarketPlace' ?
              nftData?.bidEndDate && nftData?.isBidOpen && <p>Expiry Date :{nftData?.bidEndDate}</p>
              : nftData?.expiryDate && nftData?.isClaim && 
              <p>Expiry Date : {nftData?.expiryDate?.split("T")[0]}</p>
            }
            
            {
              !nftData?.isClaim && 
              <div className="time-pnl">
              {nftData?.distributaionType == 'MarketPlace' ? nftData?.isBidOpen && (
                <p>
                  {days ? days : 0}d {hours ? hours : 0}h {minutes ? minutes : 0}m{" "}
                  {seconds ? seconds : 0}s
                </p>

              ) : <p>
                {days ? days : 0}d {hours ? hours : 0}h {minutes ? minutes : 0}m{" "}
                {seconds ? seconds : 0}s
              </p>}
            </div>
            }
        
            {nftData?.distributaionType == 'MarketPlace' ?
              nftData?.bidEndDate && nftData?.isBidOpen && <p>Expiry Date :{nftData?.bidEndDate}</p>
              : nftData?.expiryDate && !nftData?.isClaim && 
              <p>Expiry Date : {nftData?.expiryDate.split("T")[0]}</p>
            }
          </div>

        </Link>
        <div

          style={{
            paddingRight: "15px",
            paddingLeft: "15px",

          }}
        >
          {/* bidInitialMaximumAmount */}
          <div className="price-min-height">

            <h5>{(nftData?.staus == "ReadyForSell" || nftData?.staus == "isBidOpen") ? 'Price' : ''}</h5>
          </div>
          <div className="flex-div" >
            {/* staus

          ReadyForSell
          isBidOpen */}

            {nftData?.staus == "ReadyForSell" ? <h6 >
              {nftData?.buyPrice}
              <img src={iconetherium} height={20} width={20} alt="icon-etherium" />
            </h6> : nftData?.staus == "isBidOpen" ? <h6>
              {nftData?.bidInitialMaximumAmount}
              <img src={iconetherium} height={20} width={20} alt="icon-etherium" />
            </h6> : <h6></h6>}
            <h6>
              {nftData?.isFavourite ?
                <i
                  // onClick={() => {
                  //   UnLikeNFT(nftData?.id)
                  // }}
                  className="fa fa-heart" style={{ color: "red", cursor: "pointer" }}></i>
                :
                <i
                  // onClick={() => {
                  //   LikeNFT(nftData?.id)
                  // }} 
                  className="fa fa-heart-o" style={{ cursor: "pointer" }}></i>
              }
              <span style={{ paddingLeft: "3px" }}>
                {nftData?.nftFavouritesCount}
              </span>
            </h6>
          </div>
        </div>
      </div >

    </>
  );
}
export default CollectionPost;
