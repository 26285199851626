import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Button, Modal, Table } from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";
import { Form } from "react-router-dom";
import { Searchbar } from "../Components/Searchbar";
import { Pagination } from "react-pagination-bar";

import { handleGetRedeemActivity, handleGetUserList } from "../shared/api";
import moment from "moment";
import { API_URL, Node_BE_URL } from "../constant";
import { DateRange } from 'react-date-range'; // Import DateRange
import 'react-date-range/dist/styles.css'; // Include styles for the date range picker
import 'react-date-range/dist/theme/default.css';
import axios from "axios";

const RedemptionActivity = () => {
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loader, setloader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [productsLoading, setProductsLoading] = useState(true); // New loading state for products


    // State variables for date range
    const [dateRange, setDateRange] = useState([
      {
        startDate: null, // Default to today
        endDate: null, // Default to today
        key: 'selection'
      }
    ]);
    



  


    

  const getUserList = async(data) => {
    setloader(true);
    const { startDate, endDate } = dateRange[0]; // Get the selected start and end date
  // Check if products array has data

     await handleGetRedeemActivity(currentPage,startDate,endDate)
      .then((res) => {
        const redeemedActions = res?.data?.redeemedActions?.map(activity => {
          const product = data?.find(p => p?.id === activity?.Details?.ProductId);
          return {
            ...activity,
            Details: {
              ...activity.Details,
              ProductName: product ? product.name : "Unknown Product"
            }
          };
        });
      
        setActivities(redeemedActions);
        setTotalItems(res?.data?.pagination?.totalRedeemedActions);
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  };
  
  /**
   * Fetches a list of products from the API and stores them in a state object
   */
  const GetListsData = async () => {
    setProductsLoading(true)
  await  axios.get(`${Node_BE_URL}/api/getProducts`).then((res) => {
      getUserList(res.data.data)
      setProductsLoading(false)
    }).catch(() => {
      setProductsLoading(false)
    })
  }
 
  useEffect(() => {
   
       GetListsData(); 
    
  
  }, [currentPage,dateRange]);
/**
 * Closes the modal and clears the date range by setting both start and end dates to null.
 */
  const handleCloseModal = () => {
    setShowModal(false);
    // Reset dates to today's date when modal is closed
    setDateRange([
      {
        startDate: null, // Set to null or remove the key altogether
        endDate: null,   // S
        key: 'selection'
      }
    ]);
  };
  const handleFilter = () => {
    setShowModal(false);
    getUserList();
  };
  const getDistributionTypeName = (type) => {
    switch (type) {
      case 4:
        return "Promotion";
      case 3:
        return "Tier";
      case 2:
        return "Product";
      default:
        return "Unknown";
    }
  };
  
  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Redemption Activity</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrumb */}
            </div>
           
            <div className="full-div">
              <div className="flex-div-sm">
               
               <h1>
                Redemption Activity <span className="view-title"></span>
              </h1>
              

                {/* </div> */}
                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      {/* <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Searchbar setsearchedString={setsearchedString} handleSearch={handleSearch} searchedString={searchedString} />
                      </Form> */}
                    </div>
                  </li>

                  
                   
                  <Button className="reg-btn" onClick={() => setShowModal(true)}><i className="fa fa-plus"></i>Select Date Range</Button>
                  
                </ul>
              </div>
            </div>

            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                      <tr>
                        <th>NFT Name</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Redeem Date</th>
                        <th>Nft Type</th>
                      </tr>
                    </thead>

                    {!loader ? (
                      activities?.length > 0 ? (
                        <tbody>
                          {activities.map((activity) => (
                            <tr key={activity._id}>
                              <td>{activity.Details.NftName}</td>
                              <td>{activity.Details.ProductName}</td>
                              <td>{activity.Details.Price}</td>

                              <td>
                                {moment(activity?.Timestamp).format(
                                  "YYYY-MM-DD"
                                )}
                              </td>
                              
                              <td>
                              {getDistributionTypeName(activity.Details.DistributionType)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <tr>
                            <td colSpan="5">No Record Found</td>
                          </tr>
                        </tbody>
                      )
                    ) : (
                      <CustomLoader isLoading={loader} />
                    )}
                  </Table>
                </div>
              </div>

              <div className="full-div text-center pagination-container">
                <Pagination
                  totalItems={totalItems}
                  itemsPerPage={10}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal  centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header >
          <Button closeButton className="close-btn" onClick={handleCloseModal}><i className="fa fa-close"></i></Button>

            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
              <div className="date-picker-container">
            <DateRange
              onChange={item => setDateRange([item.selection])} // Update date range state
              ranges={dateRange} // Pass the date range to display
              editableDateInputs // Allow direct date input
              months={1} // Show two months in the calendar
              direction="horizontal" // Display the months side by side
              moveRangeOnFirstSelection={false} // Prevent moving the range on first selection
            />
            
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>




       

      </main>
    </>
  );
};

export default RedemptionActivity;
