import React, { useState, useEffect } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import { API_URLs, colors, Node_BE_URL } from "../constant";
import InfiniteScrollList from "../Components/InfiniteScrollList";
import InfiniteScroll from "react-infinite-scroll-component";


const PdfModal = ({ isOpen, onRequestClose, onSelectProduct }) => {

  const [loader, setloader] = useState(false);
  const handleClose = () => onRequestClose(false);


  function CrossIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    );
  }

  return (
    <>
    
                    <Modal
                    show={isOpen}
                    onHide={handleClose}
                    animation={true}
                    centered
                    style={{ minWidth: '100%' }}
                  >
                    <Modal.Header className="modal-header-color">
                      <Modal.Title className="" style={{ color: "#AD79F7" }}>
                        Product list
                      </Modal.Title>
                      <div
                        className="cross-modal"
                        onClick={
                          handleClose                        }
                      >
                        <CrossIcon />
                      </div>
  
                      
                    </Modal.Header>
                    <Modal.Body className="modal-body-color">
                    <InfiniteScrollList setloader={setloader} onPress={onSelectProduct} closeModal={() => onRequestClose(false)} />

                  </Modal.Body>
                    
                  </Modal>
                  </>
  );
};

export default PdfModal;
