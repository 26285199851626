import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CrossIcon } from '../constant';
/**
 * LocationModal component for selecting a store location.
 * @param {boolean} show - Determines if the modal is visible.
 * @param {function} onHide - Callback to close the modal.
 * @param {Array} locations - List of available locations to select from.
 * @param {function} onSelect - Callback to pass the selected location back to the parent component.
 * @param {Object} initialLocation - The initial location to be pre-selected when the modal opens.
 */
const LocationModal = ({ show, onHide, locations, onSelect, initialLocation }) => {
    const [selectedLocation, setSelectedLocation] = useState(initialLocation ? initialLocation?.id : null);
 /**
   * Effect that runs when the modal opens or when the initialLocation changes.
   * If the modal is shown and an initialLocation is provided, set the selected location id.
   * If the modal is closed or no initialLocation exists, reset selectedLocation to null.
   */
  useEffect(() => {
    // When the modal opens, initialize the selected location (if any)
    if (show && initialLocation) {
      setSelectedLocation(initialLocation.id);
    }
  }, [show, initialLocation]);
/**
   * Handles the change of the selected location when a radio button is clicked.
   * @param {Object} location - The location object that was selected.
   */
  const handleLocationChange = (location) => {
    setSelectedLocation(location?.id); // Update the selected location
  };
 /**
   * Confirms the selected location and calls the onSelect callback with the selected location object.
   * Closes the modal after selection is confirmed.
   */
  const handleConfirmSelection = () => {
    const selected = locations?.find(location => location?.id === selectedLocation);
    if (selected) {
      onSelect(selected); // Pass the selected location back to parent
    }
    onHide(); // Close the modal
  };

  return (
    <Modal 
      show={show} 
      onHide={onHide}
      animation={true}
      centered
      style={{ minWidth: '100%' }}
    >
      <Modal.Header className="modal-header-color">
        <Modal.Title style={{ color: "#AD79F7" }}>Select Store</Modal.Title>
        <div className="cross-modal" onClick={onHide}>
          <CrossIcon />
        </div>
      </Modal.Header>

      <div id="scrollableDiv" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        <Modal.Body className="modal-body-color">
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {locations?.map(location => (
              <li key={location?.id} style={{ cursor: 'pointer' }}>
                <label>
                  <input
                    type="radio"
                    name="location"  
                    checked={selectedLocation === location?.id}  // Check if the current location is selected
                    onChange={() => handleLocationChange(location)}  // Update the selected location
                  />
                  {location?.name}
                </label>
              </li>
            ))}
          </ul>
        </Modal.Body>
      </div>

      <Modal.Footer>
        <Button className='reg-btn white' onClick={onHide}>
          Close
        </Button>
        <Button className='reg-btn white' onClick={handleConfirmSelection}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocationModal;
