

import { Searchbar } from './Searchbar';
import ClipLoader from "react-spinners/ClipLoader";
import React, { useState, useEffect, CSSProperties } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import fetchDataFromApi from './api'; // Replace with your API function
import { Form, ListGroup } from 'react-bootstrap';
import { Node_BE_URL, WareHouse_API_URL, colors } from '../constant';
import axios from 'axios';

const InfiniteScrollList = ({ closeModal, count, onPress }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const [page, setpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setsearchLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const totalItems = count;
  const itemsPerPage = 200;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleSearch = () => {
    setLoading(true);
    // const searchTerm = searchQuery.split("'")[1]?.trim() || searchQuery.trim();

    axios.post(`${Node_BE_URL}/api/getProductsbyName`,{
      name :searchQuery
    }).then((res) => {
      setData(res?.data?.data)
      setLoading(false)
    }).catch(() => {

    })


  };


  const GetListsData = async (Page) => {
    setLoading(true);
    axios.get(`${Node_BE_URL}/api/getProducts`).then((res) => {
      setData(res?.data?.data)
      setLoading(false)
    }).catch(() => {

    })
  }
  useEffect(() => {
    if (!searchQuery) {
      GetListsData(1)
      setpage(1)
    }
  }, [searchQuery]);
  const nextData = () => {
    const temp = page;
    GetListsData(temp + 1)
    setpage(page + 1)
  }
  return (
    <div>
      <div className="search-pnl mb-4">
        <div className="search-pnl mb-4">
          <Searchbar
            setsearchedString={setSearchQuery}
            handleSearch={handleSearch}
            searchedString={searchQuery}
          />
        </div>
      </div>
      <div id="scrollableDiv" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        <InfiniteScroll
          dataLength={data?.length}
          next={!searchQuery && nextData}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          loader={loading && <div className='loader-style'>
            <ClipLoader
              color={colors.mainColor}
              loading={loading}
              size={30}
            />
          </div>
          }
        >
          <ListGroup variant="flush">
            {!searchLoading && data?.map((item, index) => (<>
              <ListGroup.Item
                key={index}
                style={{ maxHeight: item?.name?.length > 54 ? '100px' : '75px' }}
                action
                onClick={() => {
                  closeModal();
                  onPress(item);
                }}
              >
               
                {item?.name} {/* Renders the item's name */}

                {/* Renders item's SKU if it exists */}
                {item?.sku && (
                  <p id='para'>
                    {item?.sku} {`(${item?.companyid})`}{" "}
                    {/* {item?.location_name} */}
                  </p>
                )}


                {/* Renders item's company ID if it exists */}
            
              </ListGroup.Item>
              {/* <ListGroup.Item key={index} action onClick={() => {
                // closeModal();
                // onPress(item);
              }}>
                npm
              </ListGroup.Item> */}
            </>
            ))}

            {!loading && !data?.length && <p>No Record Found.</p>}
          </ListGroup>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default InfiniteScrollList;
