import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap';
import Sidebar from '../Components/Sidebar';
import CustomLoader from '../Components/CustomeLoader';
import Header from '../Components/header';
import BannerForm from './BannerForm';
import BannerPreview from './BannerPreview';

function BannerGeneration() {
    const [loader, setloader] = useState(true);
    const [bannerData, setBannerData] = useState(null);

    const handleFormSubmit = (data) => {
      setBannerData(data);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setloader(false); // Set loader to false after 3 seconds
        }, 300);
    
        return () => clearTimeout(timer); // Cleanup the timer when the component unmounts
      }, []);
  
  return (
    <>
    <Sidebar />
    <CustomLoader isLoading={loader} />
    <main className='dasboard-main'>
      <div className='dashboard-inner-pnl'>
        <Header />
        <div className='head-inner-panel'>
          <div className='full-div'>
            <Breadcrumb>
              <Breadcrumb.Item>Admin Management</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Generation Banner Image</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {/* <div className='full-div'>
            <h1>Product Banner Ganeration</h1>
            <div className='spacer-20'></div>
          </div> */}
          <div className='full-div'>
          <BannerForm onFormSubmit={handleFormSubmit} />
      <BannerPreview bannerData={bannerData} />

                      </div>
        </div>
      </div>
    </main>
  </>
  )
}

export default BannerGeneration
