import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Breadcrumb, Row, Button } from "react-bootstrap";
import { Pagination } from "react-pagination-bar";

import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import CollectionPost from "../Components/CollectionPost";
import {
  getExpiredPromoNftsByCollectionId,
  handleGetCollectionbyId,
  handleGetNftByCollectionId,
} from "../shared/api";
import CustomLoader from "../Components/CustomeLoader";
import { useSelector } from "react-redux";
import { rightSelector } from "../redux/rightSlice";
import { API_URL, Node_BE_URL } from "../constant";
import axios from "axios";
import { toast } from "react-toastify";
import Reset from "../assets/images/reset.png";
import Expire from "../assets/images/expire.png";

import { Searchbar } from "../Components/Searchbar";
import LocationModal from "./LocationModal";

const ITEMS_PER_PAGE = 12;

function Collection() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { rightAccess } = useSelector(rightSelector);

  const [NFTFilters, setNFTFilters] = useState("");
  const [collectData, setcollectData] = useState({});
  const [collectioNft, setcollectioNft] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [parentprorp, setparentprorp] = useState(false);
  const [loader, setloader] = useState(false);
  const [checked, setchecked] = useState(null);
  const [serachString, setserachString] = useState('');
  const [locations, setLocations] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const CHECKS = {
    TierBased: 1,
    Productbased: 2,
    Marketbased: 3,
    Promotional:7,
    OnSale: 4,
    HasOffer: 5,
    OnAuction: 6,
    nftExpireStatus:8

  }

  useEffect(() => {
    handleGetCollectionbyId(id)
      .then((res) => {
        setcollectData(res.data.data);
      })
      .catch((err) => { });
  }, [id]);

  useEffect(() => {
    getNftColletion(currentPage)
  }, [currentPage, parentprorp,checked ,selectedLocation]);

  // useEffect(() => {
  //   getNftColletion(1)
  //   setCurrentPage(1)
  // }, [checked]);

  // useEffect(() => {
  //   if (serachString.length == 0) {
  //     getNftColletion(1)
  //   }
  // }, [serachString]);

  const getNftColletion = (currentPage = 1) => {

    setloader(true);
    const filters = checked == CHECKS.Promotional ? { distributionType: "Promotion" } : NFTFilters;

    handleGetNftByCollectionId(id, currentPage, "", NFTFilters, checked == CHECKS.TierBased, checked == CHECKS.Productbased, checked == CHECKS.Marketbased  , checked == CHECKS.Promotional ,checked == CHECKS.nftExpireStatus,selectedLocation  )
      .then((res) => {
        const filteredData = checked == CHECKS.Promotional 
        ? res.data.data.filter(item => item.distributionType === 'Promotion') 
        : res.data.data;
        setcollectioNft(filteredData);
        setTotalItems(res.data.totalItems);
        setloader(false);
      })
      .catch((error) => {
        setloader(false);
      });
  }

  const handleSearch = () => {

    setloader(true);
    handleGetNftByCollectionId(id, 1, serachString, NFTFilters, checked == CHECKS.TierBased,checked == CHECKS.Promotional, checked == CHECKS.Productbased, checked == CHECKS.Marketbased,checked == CHECKS.nftExpireStatus)
      .then((res) => {
        setTotalItems(res.data.totalItems);
        setcollectioNft(res.data.data);
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  };

  const dataAfterDelete = (id) => {
    setcollectioNft((prev) => [...collectioNft?.filter((x) => x.id !== id)]);
  };

  /**
 * Fetches expired promotional NFTs for a specific collection and updates state with the data and total item count.
 * Manages loading states by setting the loader visibility before and after data fetching.
 */

  const GetExpiredPromoNft = () => {

    setloader(true);
    getExpiredPromoNftsByCollectionId(id)
    .then((res) => {
      setcollectioNft(res?.data?.data || []);
      setTotalItems(res.data.totalItems || 0);

setloader(false)
    })
    .catch((err) => {
      setloader(false);
    
    });
  }
  useEffect(() => {
    GetAllLocations(); // Fetch locations when component mounts
  }, []);
/**
 * Fetches all location data from the API and updates the state with the response.
 * The `useEffect` hook calls this function when the component mounts to load locations initially.
 */
  const GetAllLocations = () => {
    axios.get(`${Node_BE_URL}/api/admin/getAllLocations`)
      .then((data) => {
        setLocations(data.data);
      })
      .catch((err) => { });
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location); // Update the selected location
    setModalShow(false); // Close the modal
  };


 

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div" style={{ maxWidth: "1400px" }}>
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="/NFTCollectionManagement">
                  NFT Collection Management
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#" className="breadcrumb-item"> 
                  {collectData && collectData.name}
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            {loader ? (
              <>
                {/* <Row style={{ justifyContent: "center" }}>
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </Row> */}
              </>
            ) : (
              <>
                <div className="full-div" style={{ maxWidth: "1400px" }}>
                  <div className="flex-div-sm">
                    <h1 className="breadcrumb-item"> {collectData && collectData.name}</h1>
                    <ul className="btn-lister">
                      <li>
                        <div className="search-pnl">
                          <Form onSubmit={(e) => e.preventDefault()}>
                            <Searchbar setsearchedString={setserachString} handleSearch={handleSearch} searchedString={serachString} />

                          </Form>

                        </div>
                      </li>

                      {rightAccess?.some(
                        (right) =>
                          right.screenName.toLowerCase() ===
                          "nft collection management" && right.isAdd
                      ) && (
                          <li>
                            <Button
                              onClick={() => {
                                collectData.isApproved ?
                                  navigate("/AddNewNFT", {
                                    state: { collectionID: id },
                                  }) : toast.warning("Collection not approved. Please contact admin.");

                              }}
                              className="reg-btn"
                            >
                              <i className="fa fa-plus"></i> Add New NFT
                            </Button>
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
                <div className="collection-container">
                  {/* <div
                    className="reg-btn mr-2 mb-2"
                    onClick={() => {
                      setchecked(CHECKS.Marketbased)
                      setCurrentPage(1)
                      setNFTFilters("")
                    }}
                    style={{
                      background: checked == CHECKS.Marketbased ? "#9e75ff" : "white",
                      color: checked == CHECKS.Marketbased ? "white" : "#9e75ff",
                      border: "1px solid #9e75ff",
                      cursor: "pointer"
                    }}


                  >MarketPlace based</div> */}
                    <div
                    className="reg-btn mr-2 mb-2"
                    onClick={() => {
                      setchecked(CHECKS.Promotional)
                      setCurrentPage(1)
                      setNFTFilters("")
                    }}
                    style={{
                      background: checked == CHECKS.Promotional ? "#9e75ff" : "white",
                      color: checked == CHECKS.Promotional ? "white" : "#9e75ff",
                      border: "1px solid #9e75ff",
                      cursor: "pointer"
                    }}


                  >Promotional Based</div>

                  <div
                    className="reg-btn mr-2 mb-2"
                    onClick={() => {
                      setchecked(CHECKS.TierBased)
                      setNFTFilters("")
                      setCurrentPage(1)
                    }}
                    style={{
                      background: checked == CHECKS.TierBased ? "#9e75ff" : "white",
                      color: checked == CHECKS.TierBased ? "white" : "#9e75ff",
                      border: "1px solid #9e75ff",
                      cursor: "pointer"
                    }}


                  >Tier based</div>
                  <div
                    className="reg-btn mr-2 mb-2"
                    onClick={() => {
                      setchecked(CHECKS.Productbased)
                      setNFTFilters("")
                      setCurrentPage(1)
                    }}
                    style={{
                      background: checked == CHECKS.Productbased ? "#9e75ff" : "white",
                      color: checked == CHECKS.Productbased ? "white" : "#9e75ff",
                      border: "1px solid #9e75ff",
                      cursor: "pointer"
                    }}


                  >Product based</div>
                  {/* <div
                      className="reg-btn mr-2 mb-2"
                      onClick={() => {
                        setchecked(CHECKS.OnSale)
                        setNFTFilters("ReadyForSell")

                      }}
                      style={{
                        background: checked == CHECKS.OnSale ? "#9e75ff" : "white",
                        color: checked == CHECKS.OnSale ? "white" : "#9e75ff",
                        border: "1px solid #9e75ff",
                        cursor: "pointer"
                      }}


                    >On Sale</div>
                    <div
                      onClick={() => {
                        setchecked(CHECKS.HasOffer)
                        setNFTFilters("hasoffers")

                      }}
                      style={{
                        background: checked == CHECKS.HasOffer ? "#9e75ff" : "white",
                        color: checked == CHECKS.HasOffer ? "white" : "#9e75ff",
                        border: "1px solid #9e75ff",
                        cursor: "pointer"


                      }}
                      className="reg-btn mr-2 mb-2"

                    >Has Offer</div>
                    <div
                      onClick={() => {
                        setchecked(CHECKS.OnAuction)
                        setNFTFilters("isBidOpen")


                      }}
                      style={{
                        background: checked == CHECKS.OnAuction ? "#9e75ff" : "white",
                        color: checked == CHECKS.OnAuction ? "white" : "#9e75ff",
                        border: "1px solid #9e75ff",
                        cursor: "pointer"

                      }}

                      className="reg-btn mr-2 mb-2"


                    >On Auction</div> */}
                  <div
                    className="reg-btn mr-2 mb-2 reset-container"
                    onClick={() => {
                      setchecked(null)
                      setCurrentPage(1)
                      setNFTFilters("")
                      getNftColletion();
                      setSelectedLocation(null)


                    }}

                  > <img className='reset-icon' src={Reset} /> Reset</div>
                                    
                                    
                                    
                                    
                                    <div
                    className="reg-btn mr-2 mb-2 reset-container"
                    onClick={() => {
                      setchecked(CHECKS.nftExpireStatus)
                      setNFTFilters("")
                      setCurrentPage(1)


                    }}
                    
                    style={{
                      background: checked == CHECKS.nftExpireStatus ? "#9e75ff" : "white",
                      color: checked == CHECKS.nftExpireStatus ? "white" : "#9e75ff",
                      border: "1px solid #9e75ff",
                      cursor: "pointer"
                    }}


                  > <img className='reset-icon' src={Expire} /> Expired Promotional NFTs</div>
 <div
                    className="reg-btn mr-2 mb-2 reset-container"
 onClick={() => setModalShow(true)} >
        Select Store 

      </div>
      <LocationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        locations={locations}
        onSelect={handleLocationSelect}
        initialLocation={selectedLocation}  // Pass selectedLocation as initialLocation to modal

      />

      {selectedLocation && (
        <div>
          <p>Selected Location Name: {selectedLocation.name}</p>
        </div>
      )}

                  <div className="full-div mt-3">
                    <ul className="collection-list small">
                      {collectioNft?.length > 0 ?


                        <>
                          {collectioNft?.map((nftData) => (
                            <li key={nftData?.id}>
                              <CollectionPost
                                dataAfterDelete={dataAfterDelete}
                                nftData={nftData}
                                parentdata={setparentprorp}
                                pardata={parentprorp}
                                getNftColletion={getNftColletion}
                              />
                            </li>
                          ))}

                        </>
                        :
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <p>No Record Found</p>
                        </div>
                      }
                      {/* {collectioNft?.map((nftData) => (
                        <li key={nftData?.id}>
                          <CollectionPost
                            dataAfterDelete={dataAfterDelete}
                            nftData={nftData}
                          />
                        </li>
                      ))} */}
                    </ul>
                  </div>
                  <div className="full-div text-center pagination-container">
                    <Pagination
                      totalItems={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage} 
                      onPageChange={(pageNumber) => {
                        setCurrentPage(pageNumber);
                      }}
                      customClassNames={{
                        rpbItemClassName: "custom-item",
                        rpbItemClassNameActive: "custom-item--active",
                        rpbGoItemClassName: "custom-go-item",
                        rpbItemClassNameDisable: "custom-item--disable",
                        rpbProgressClassName: "custom-progress-bar",
                        rpbRootClassName: "custom-root",
                      }}
                    />
                    {/* <Link to="/Collection" className='reg-btn big'>View More</Link> */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
}
export default Collection;
