import React, { useEffect, useState } from "react";
import { downloadSizes } from "../constant";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";

function ModelMobileNFT({
  show,
  handleClose,
  background,
  title,
  titleColor,
  textEffect,
  footer,
  showFooterInput,
  showTC,
  priceMapColor,
  titleMapColor,
  priceFontSize,
  titleFontSize,
  nameMapColor,
  nameFontSize,
  generatedQR,
  processedImage,
  productDetails,
  titleFontFamily,
  customPrice,
  nameColor,
  priceColor,
  bannerType,
}) {
  const [visible, setVisible] = useState(false);
  const selectedColor = useSelector((state) => state.color.selectedColor);


  useEffect(() => {
    if (show) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 300); 
    }
  }, [show]);

  if (!visible) return null;

  const styles = downloadSizes[bannerType] || {};
  const productDetailStyles = styles.productDetails || {};

  return (
    <div className={`modelContainer ${bannerType}`}>
      <div className={`modal-overlay ${show ? "show" : "hide"}`} onClick={handleClose}>
        <div
          className={`modal-content-live     ${show ? "fade-in" : "fade-out"}`}
          onClick={(e) => e.stopPropagation()}
          style={{
            ...styles,
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${
              background
                ? background instanceof Blob
                  ? URL.createObjectURL(background)
                  : background
                : ''
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div>
            <h1
              style={{
                color: titleMapColor|| titleColor,
                fontFamily: titleFontFamily,
                fontSize: titleFontSize,
                textAlign: "center",
                marginBottom: "20px",
              }}
              className={`titlemodel ${textEffect}`}
            >
              {title}
            </h1>
          </div>
          <div
            className="product-details-container"
            style={productDetailStyles}
          >
            {productDetails?.map((product, index) => (
              <div
                key={index}
                className="product-details"
                style={{ marginBottom: "10px" }}
              >
                {processedImage && (
                  <img
                    className="img-modal"
                    src={processedImage}
                    alt={product.name}
                  />
                )}
                {product.name && (
                  <p
                    style={{
                      fontSize: nameFontSize,
                      fontFamily: titleFontFamily,
                      color:nameMapColor|| nameColor,
                      maxWidth: "100%",
                    }}
                    className={`name-modal ${textEffect}`}
                  >
                    {product.name}
                  </p>
                )}
                {(customPrice || product.price) && (
                  <p
                    style={{
                      fontSize: priceFontSize,
                      fontFamily: titleFontFamily,
                      color:  priceMapColor || priceColor,
                    }}
                    className={`price-modal ${textEffect}`}
                  >
                     ${customPrice ? customPrice : (product.price / 100).toFixed(2)}
                  </p>
                )}
              </div>
            ))}
          </div>
          <div
            className="footer-container"
            style={{
              color: titleColor,
              fontFamily: titleFontFamily,
            }}
          >
            {(footer || showFooterInput) && (
              <div className="footer-style-NFT">{footer}</div>
            )}
            {showTC && <div className="T-and-C-NFT">T & C</div>}
            {generatedQR && (
                <div className="QR-Generator">
                  <QRCode value={generatedQR} />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModelMobileNFT;
