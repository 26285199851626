import img from '../assets/images/img_colormap.gif'
import React, { useRef, useState } from 'react';

const ColorMapPicker = ({ currentColor, setColor, applyTo }) => {
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const [selectedColor, setSelectedColor] = useState(currentColor || '');
 /**
   * This function is triggered when the user clicks on the color map image.
   * It determines the color from the clicked pixel and updates the selected color state.
   * 
   * @param {Object} event - The click event containing coordinates of the click.
   */
  const handleClick = (event) => {
    const img = imgRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);

    const rect = img.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const pixel = ctx.getImageData(x, y, 1, 1).data;
    const color = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;

    setSelectedColor(color);
    setColor(color, applyTo); // Pass applyTo to specify the target
  };


  return (
    <div style={{marginTop:"20px"}}>
      <img
        ref={imgRef}
        src={img}
        alt="Color Map"
        onClick={handleClick}
        style={{ cursor: 'crosshair' }}
      />
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    </div>
  );
};

export default ColorMapPicker;
